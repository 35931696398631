import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Statement from './Statement';


class ConcertForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            odKogo: '',
            dlaKogo: '',
            email: '',
            zyczenia: '',
            agreement: false,
            sending: false,
            statement: '',

            errors: {
                odKogo: false,
                dlaKogo: false,
                email: false,
                zyczenia: false,
                agreement: false,
            }
        }
    }

    messages = {
        odKogo_incorrect: 'Podaj minimum 3 znaki lub maksymalnie 40 znaków, bez cyfr',
        dlaKogo_incorrect: 'Podaj minimum 3 znaki lub maksymalnie 40 znaków, bez cyfr',
        email_incorrect: 'Podaj prawidłowy adres email',
        zyczenia_incorrect: 'Podaj mininum 10 znaków, maksymalnie 1000 znaków',
        agreement_incorrect: 'Zaakceptuj politykę prywatności, jeśli zgadzasz się na wysłanie tej wiadomości.',
    }

    patterns = {
        email: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
        text: '\\b[^\\d\\W]+\\b',
        textarea: '.+',
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;

        if (type === 'email' || type === 'text' || type === 'textarea') {
            this.inputValidation(name, value, type);
            this.setState({
                [name]: value,
            })
        } else if (type === 'checkbox') {
            let errors = this.state.errors;
            errors.agreement = this.state.agreement;

            this.setState(prevState => ({
                [name]: !prevState.agreement,
                errors
            }))
        }
    }

    inputValidation = (name, value, type) => {
        let errors = this.state.errors;

        if (type === 'text') {

            const textReg = new RegExp(this.patterns.text, "gi");
            if (value.length > 2 && value.length <= 40 && textReg.test(value))
                errors[name] = false;
            else errors[name] = true;

        } else if (type === 'textarea') {

            const textareaReg = new RegExp(this.patterns.textarea, "gi");
            if (value.length > 10 && value.length <= 1000 && textareaReg.test(value))
                errors[name] = false;
            else errors[name] = true;

        } else if (type === 'email') {

            const emailReg = new RegExp(this.patterns.email, "gi");
            if (value.length > 10 && value.length <= 60 && emailReg.test(value))
                errors[name] = false;
            else errors[name] = true;

        } else if (type === 'checkbox')
            this.state.agreement ? errors.agreement = false : errors.agreement = true;

        this.setState({ errors });
    }

    formValidation = () => {

        this.inputValidation('odKogo', this.state.odKogo, 'text');
        this.inputValidation('dlaKogo', this.state.dlaKogo, 'text');
        this.inputValidation('email', this.state.email, 'email');
        this.inputValidation('zyczenia', this.state.zyczenia, 'textarea');
        this.inputValidation('agreement', this.state.agreement, 'checkbox');

        return !this.state.errors.odKogo && !this.state.errors.dlaKogo && !this.state.errors.email && !this.state.errors.zyczenia;

    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { odKogo, dlaKogo, email, zyczenia, agreement } = this.state;

        if (this.formValidation() && agreement) {
            const url = "https://radio.ainkarim.pl/api/concert/send.php";

            const data = {
                "odKogo": odKogo,
                "dlaKogo": dlaKogo,
                "email": email,
                "zyczenia": zyczenia,
                "agreement": Number(agreement)
            };

            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(response => {
                    this.setState({
                        sending: false,
                        statement: response.message,
                    });
                })
                .catch(error => {
                    this.setState({
                        statement: 'Wystąpił błąd. Prosimy spróbować ponownie',
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        this.setState({
                            statement: '',
                        });
                    }, 10000);
                })

            this.setState({
                odKogo: '',
                dlaKogo: '',
                email: '',
                zyczenia: '',
                agreement: false,
                sending: true,
                statement: 'Wysyłanie życzeń',

                errors: {
                    odKogo: false,
                    dlaKogo: false,
                    email: false,
                    zyczenia: false,
                    agreement: false,
                }
            });
        }
    }


    render() {
        const { odKogo, dlaKogo, zyczenia, email, agreement } = this.state.errors;
        const { odKogo_incorrect, dlaKogo_incorrect, zyczenia_incorrect, email_incorrect, agreement_incorrect } = this.messages;

        return (
            <>
                {this.state.statement.length ? <Statement statement={this.state.statement} sending={this.state.sending} /> :
                    <form className="form" onSubmit={this.handleSubmit} noValidate>
                        <label className="form-item" htmlFor="odKogo">
                            <input name="odKogo" type="text" placeholder="Od kogo" value={this.state.odKogo} onChange={this.handleChange} className={`${odKogo ? 'error' : ''}`} />
                            {odKogo && <div className="form-error-msg">{odKogo_incorrect}</div>}
                        </label>

                        <label className="form-item" htmlFor="dlaKogo">
                            <input name="dlaKogo" type="text" placeholder="Dla kogo" value={this.state.dlaKogo} onChange={this.handleChange} className={`${dlaKogo ? 'error' : ''}`} />
                            {dlaKogo && <div className="form-error-msg">{dlaKogo_incorrect}</div>}
                        </label>

                        <label className="form-item" htmlFor="email">
                            <input name="email" type="email" placeholder="Adres e-mail" value={this.state.email} onChange={this.handleChange} className={`${email ? 'error' : ''}`} />
                            {email && <div className="form-error-msg">{email_incorrect}</div>}
                        </label>


                        <label className="form-item" htmlFor="zyczenia">
                            <textarea name="zyczenia" id="zyczenia" cols="30" rows="10" placeholder="Treść życzeń" value={this.state.zyczenia} onChange={this.handleChange} className={`${zyczenia ? 'error' : ''}`} />
                            {zyczenia && <div className="form-error-msg">{zyczenia_incorrect}</div>}
                        </label>

                        <label className="item-agreement" htmlFor="agreement">
                            <input type="checkbox" id="agreement" name="agreement" checked={this.state.agreement} onChange={this.handleChange} />
                            <p>
                                Zgodnie z <Link to="/polityka-prywatnosci" target="_blank" title="Polityka prywatności">polityką prywatności</Link>. Wyrażam zgodę na przetwarzanie moich danych osobowych
                                {agreement && <span className="form-error-msg">{agreement_incorrect}</span>}
                            </p>

                        </label>

                        <button className="btn-form">Wyślij życzenia</button>
                    </form>}
            </>
        );
    }
}

export default ConcertForm;